export const routes = [
  {
    path: '/sign-up',
    component: () => import('../views/SignUp.vue'),
    meta: { authRoute: true }
  },

  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/dim',
    component: () => import('../views/Dim.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/personalize/select-house',
    component: () => import('../views/SelectHouse.vue')
  },
  {
    path: '/personalize/avatar-builder',
    component: () => import('../views/AvatarBuilder.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/AvatarBuilder/AvatarBuilder_1.vue')
      },
      {
        path: '1',
        component: () => import('../views/AvatarBuilder/AvatarBuilder_1.vue')
      },
      {
        path: '2',
        component: () => import('../views/AvatarBuilder/AvatarBuilder_2.vue')
      },
      {
        path: '3',
        component: () => import('../views/AvatarBuilder/AvatarBuilder_3.vue')
      }
    ]
  },
  {
    path: '/personalize/avatar-final',
    component: () => import('../views/AvatarFinal.vue')
  },
  {
    path: '/error/:error',
    component: () => import('../views/Error.vue')
  },
  {
    path: '/',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'hr',
        component: () => import('../views/HR.vue')
      },
     
      {
        path: 'new-hires',
        component: () => import('../views/NewHires.vue')
      },
      {
        path: 'new-hires-task/:taskID',
        component: () => import('../views/NewHireTask.vue')
      },
      {
        path: 'task',
        component: () => import('../views/Task.vue')
      },
      {
        path: 'setting-up',
        component: () => import('../views/SettingUp.vue')
      },
      {
        path: 'task/:taskID',
        component: () => import('../views/Task.vue')
      },
      {
        path: 'courses',
        component: () => import('../views/Courses.vue')
      },
      {
        path: 'resource-library',
        component: () => import('../views/ResourceLibrary.vue')
      },
      {
        path: 'support',
        component: () => import('../views/Support.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/error/404",
  }
]

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script setup>
import { useStore } from "vuex";
import AuthAPI from "@/api/AuthAPI";
import UserAPI from "@/api/UserAPI";
import ChecklistAPI from "@/api/ChecklistAPI";
import { userNames } from "@/store/modules/user";
import { avatarNames } from "@/store/modules/avatar";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";

const router = useRouter();
const route = useRoute();
const store = useStore();
// VALIDATE AND GET USER
onMounted(async () => {
  let avatar;
  let user;
  try {
    user = await UserAPI.getMyUser();
    console.log("User:", user);
  } catch (e) {
    console.log(e);
  }
  const hiredDate = user.job_position_effective_date;
  const parsedDate = Date.parse(hiredDate);
  const currentDate = Date.now();
  if (currentDate - parsedDate < 7889400000) user.is_new_hire = true;
  console.log("date:", currentDate - parsedDate);
  console.log("parsed date:", parsedDate);
  user.first_name = user.preferred_name
    ? user.preferred_name
    : user.employee_first_name;
  user.last_name = user.preferred_last_name
    ? user.preferred_last_name
    : user.employee_last_name;
  if (!user.role) {
    if (user.job_profile_id === "HDISIC") user.role = "IC";
    if (user.job_profile_id === "SHDISD") {
      user.is_leader = true;
      user.role = "DIM";
    }
    if (user.job_profile_id === "SBHDEI") {
      user.is_leader = true;
      user.is_new_hire = false;
      user.role = "BIM";
    }
    {
    }
    if (user.job_profile_id === "HDISAM") user.role = "AIS";
  }
  await store.commit(userNames.setUser, user);

  try {
    avatar = await UserAPI.getMyAvatar();
    console.log("avatar@:", avatar);
    if (avatar.id) {
      // user.avatar = avatar
      await store.commit(avatarNames.setAvatar, avatar);
      console.log("storeAvatar:", store.state.avatar);
      // store.commit(userNames.setUser, user);
    }
    if (
      user.role === "DIM" &&
      router.options.history.state.back === null &&
      user.is_new_hire
    ) {
      await router.push("/dim");
    }
    if (
      user.is_leader &&
      !user.is_new_hire &&
      !route.path.includes("new-hire")
    ) {
      await router.push("/new-hires");
    }
  } catch (e) {
    console.log(e);
  }
  // const avatar = await UserAPI.getMyAvatar()
  // console.log('avatar:', avatar)
  // user.avatar = avatar
  // store.commit(userNames.setUser, user);
  try {
    console.log("user:", store.state.user);
    // REDIRECTS
    console.log(router.options.history.state);
  } catch (error) {
    console.error("Invalid token");
  }
})();
</script>

<style>
@import url("assets/vendor/HelveticaNeueLtStd.css");
@import url("assets/vendor/CabinSketch.css");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #f4f6f5;
  font-size: 14px;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  color: #5f6562;
}

button,
input,
select,
textarea {
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
}

p {
  line-height: 1.3em;
}

/* ANIMATION */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter-active {
  transition-delay: 0.25s;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
